<template>    
    <div>
        <svg :width="dimensions.width ? dimensions.width : 36" :height="dimensions.height ? dimensions.height : 36" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="status === 'mute'">
            <path d="M18.4265 25.8786H14.2805C13.9125 25.8786 13.607 25.7569 13.3642 25.5135C13.1214 25.27 13 24.9638 13 24.5948V20.8052C13 20.4362 13.1214 20.13 13.3642 19.8865C13.607 19.6431 13.9125 19.5214 14.2805 19.5214H18.4265L22.6827 15.2545C22.9406 14.996 23.2409 14.9344 23.5836 15.0698C23.926 15.2054 24.0972 15.4576 24.0972 15.8263V29.5737C24.0972 29.9424 23.926 30.1946 23.5836 30.3302C23.2409 30.4656 22.9406 30.404 22.6827 30.1455L18.4265 25.8786ZM22.5119 17.6937L19.1035 21.1107H14.5853V24.2893H19.1035L22.5119 27.7063V17.6937Z" fill="#E8EAED"/>
            <path d="M27.5118 26.5631L30.2306 23.8374L32.9498 26.5631C33.0788 26.6924 33.2215 26.757 33.3781 26.757C33.5348 26.757 33.6777 26.6924 33.8066 26.5631C33.9353 26.4341 33.9958 26.2871 33.9882 26.1223C33.9803 25.9572 33.9198 25.818 33.8066 25.7045L31.0874 22.9785L33.8066 20.2525C33.9353 20.1234 33.9997 19.9803 33.9997 19.8232C33.9997 19.666 33.9353 19.5229 33.8066 19.3939C33.6777 19.2646 33.531 19.2039 33.3666 19.2118C33.2021 19.2195 33.0632 19.2802 32.9498 19.3939L30.2306 22.1196L27.5118 19.3939C27.3828 19.2646 27.24 19.2 27.0832 19.2C26.9267 19.2 26.7839 19.2646 26.655 19.3939C26.5263 19.5229 26.4657 19.6698 26.4734 19.8347C26.4813 19.9997 26.5418 20.139 26.655 20.2525L29.3742 22.9785L26.655 25.7045C26.5263 25.8335 26.4619 25.9766 26.4619 26.1338C26.4619 26.291 26.5263 26.4341 26.655 26.5631C26.7839 26.6924 26.9306 26.753 27.095 26.7452C27.2595 26.7375 27.3984 26.6768 27.5118 26.5631Z" fill="#E8EAED"/>
            <circle cx="23" cy="23" r="22" stroke="#969696" stroke-width="2"/>
        </svg>
        <svg :width="dimensions.width ? dimensions.width : 36" :height="dimensions.height ? dimensions.height : 36" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg" v-else>
            <path d="M19.0843 25.8786H14.9453C14.5779 25.8786 14.273 25.7569 14.0306 25.5135C13.7882 25.27 13.667 24.9638 13.667 24.5948V20.8052C13.667 20.4362 13.7882 20.13 14.0306 19.8865C14.273 19.6431 14.5779 19.5214 14.9453 19.5214H19.0843L23.3332 15.2545C23.5909 14.996 23.8906 14.9344 24.2325 15.0698C24.5743 15.2054 24.7453 15.4576 24.7453 15.8263V29.5737C24.7453 29.9424 24.5743 30.1946 24.2325 30.3302C23.8906 30.4656 23.5909 30.404 23.3332 30.1455L19.0843 25.8786ZM31.867 22.7C31.867 23.568 31.7077 24.3917 31.389 25.171C31.0707 25.9505 30.6244 26.6276 30.0502 27.2021C29.8674 27.3406 29.668 27.3625 29.4519 27.2676C29.2359 27.1728 29.1279 27.0073 29.1279 26.771V18.5495C29.1279 18.3133 29.2359 18.1477 29.4519 18.0529C29.668 17.9581 29.8674 17.9799 30.0502 18.1184C30.6244 18.6993 31.0707 19.3911 31.389 20.1937C31.7077 20.9965 31.867 21.832 31.867 22.7ZM23.1626 17.6937L19.76 21.1107H15.2496V24.2893H19.76L23.1626 27.7063V17.6937Z" fill="#E8EAED"/>
            <circle cx="23" cy="23" r="22" stroke="#969696" stroke-width="2"/>
        </svg>
    </div>
</template>
<script>
    export default {
        name: 'VolumeIcon',
        props:['status', 'dimensions'],
        mounted() {
        }
    }
</script>
<style scoped lang="scss">
</style>